<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				<span>Amazon订单</span>
			</h3>
		</div> -->
		
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">Amazon<span>{{$t('i18nn_37181c74559db182')}}</span></span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
						<!-- <li> -->
							<el-button type="success" @click="updateGoodsAction" size="small" icon="el-icon-refresh">{{$t('i18nn_3f514bfb88a0f35b')}}</el-button>
						<!-- </li> -->
						<!-- <li> -->
							<el-button type="success" @click="updateAddressAction" size="small" icon="el-icon-refresh">{{$t('i18nn_a1a651a01a9c6815')}}</el-button>
						<!-- </li> -->
						<!-- <li> -->
							<el-button type="success" @click="updateBuyinfoAction" size="small" icon="el-icon-refresh">{{$t('i18nn_fadd975428c8c511')}}</el-button>
						<!-- </li> -->
					<!-- </ul> -->
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为录入仓库的SKU数据
          </p>
        </div>
    </div> -->
		<div class="filterCon" style="">
			<div class="filterBarList">
				
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="updateGoodsAction" size="small" icon="el-icon-refresh">{{$t('i18nn_3f514bfb88a0f35b')}}</el-button>
					</li>
					<li>
						<el-button type="success" @click="updateAddressAction" size="small" icon="el-icon-refresh">{{$t('i18nn_a1a651a01a9c6815')}}</el-button>
					</li>
					<li>
						<el-button type="success" @click="updateBuyinfoAction" size="small" icon="el-icon-refresh">{{$t('i18nn_fadd975428c8c511')}}</el-button>
					</li>
				</ul> -->
				<ul class="filterConList">
					<li>
						<span>{{$t('i18nn_68efabd029c860a9')}}</span>
						<el-date-picker
							v-model="amazonPull.lastUpdatedAfter"
							type="datetime"
							size="small"
							:clearable="false"
							:placeholder="$t('i18nn_58169349e34744e8')"
							align="right"
							:picker-options="pickerOptions"
							format="yyyy-MM-dd HH:mm:ss"
							value-format="yyyy-MM-dd HH:mm:ss"
						></el-date-picker>
					</li>
					<li>
						<span>{{$t('i18nn_b17342e22700eb32')}}</span>
						<SelAmazonUserList @change="changeAmazonUser"></SelAmazonUserList>
					</li>
					<li>
						<span v-if="amazonPull.spSellerId"><el-button type="success" @click="pullOrderAction" size="small" icon="el-icon-refresh">{{$t('i18nn_43ef2ec33ab0b489')}}</el-button></span>
						
					</li>
					<li>
						<span>order Status:</span>
						<!-- <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.orderStatus" maxlength="100" :clearable="true" style="width: 200px;"></el-input> -->
						<el-select filterable clearable v-model="filterData.orderStatus" :placeholder="$t('FormMsg.Please_select')" size="small" style="width: 100px;" @change="serPageData()">
						  <el-option v-for="item in selectOption.statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</li>
					<li>
						<span>order Id:</span>
							<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.orderId" maxlength="100" :clearable="true" style="width: 200px;"></el-input>
						
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
				</ul>
			</div>
		</div>
		
		<!-- <div class="wh-filter-con"> -->
			<!-- <div class="wh-filter-item">
				<span>SKU:</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsSku" maxlength="50" :clearable="true" style="width: 250px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('Storage.skuInfo.Chinese_title') }}:</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsName" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('Storage.skuInfo.English_title') }}:</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsNameEn" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
				<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button> -->
		<!-- </div> -->

		<!-- <div class="wh-action-con">
			<div class="wh-filter-item">
				<span>{{$t('i18nn_68efabd029c860a9')}}</span>
				<el-date-picker
					v-model="amazonPull.lastUpdatedAfter"
					type="datetime"
					size="small"
					:clearable="false"
					:placeholder="$t('i18nn_58169349e34744e8')"
					align="right"
					:picker-options="pickerOptions"
					format="yyyy-MM-dd HH:mm:ss"
					value-format="yyyy-MM-dd HH:mm:ss"
				></el-date-picker>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_b17342e22700eb32')}}</span>
				<SelAmazonUserList @change="changeAmazonUser"></SelAmazonUserList>
			</div>
			<span v-if="amazonPull.spSellerId"><el-button type="success" @click="pullOrderAction" size="small" icon="el-icon-refresh">{{$t('i18nn_43ef2ec33ab0b489')}}</el-button></span>
		</div> -->
		
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>order Status:</span>
				<el-select filterable clearable v-model="filterData.orderStatus" :placeholder="$t('FormMsg.Please_select')" size="small" style="width: 100px;" @change="serPageData()">
				  <el-option v-for="item in selectOption.statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<div class="wh-filter-item">
				<span>order Id:</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.orderId" maxlength="100" :clearable="true" style="width: 200px;"></el-input>
			</div>
			<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div>

		<div class="wh-action-con">
			<el-button type="success" @click="updateGoodsAction" size="small" icon="el-icon-refresh">{{$t('i18nn_3f514bfb88a0f35b')}}</el-button>
			<el-button type="success" @click="updateAddressAction" size="small" icon="el-icon-refresh">{{$t('i18nn_a1a651a01a9c6815')}}</el-button>
			<el-button type="success" @click="updateBuyinfoAction" size="small" icon="el-icon-refresh">{{$t('i18nn_fadd975428c8c511')}}</el-button>
		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table
				ref="multipleTable"
				:data="tableData"
				stripe
				:border="true"
				style="width: 100%"
				size="small"
				:max-height="$store.state.frameConHeightWh"
				@selection-change="handleSelectionChange2"
				@row-click="handleCurrentChange2"
			>
				<!-- <el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column> -->
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->
				<el-table-column label="" fixed="left" align="center" width="50">
					<template slot-scope="scope">
						<el-radio :label="scope.$index" v-model="radio">&nbsp;</el-radio>
					</template>
				</el-table-column>

				<el-table-column prop="orderStatus" label="orderStatus"></el-table-column>

				<!-- <el-table-column prop="orderStatusName" label="orderStatusName"></el-table-column> -->

				<el-table-column prop="amazonOrderId" :label="'amazonOrderId'"></el-table-column>

				<el-table-column prop="currencyCode" :label="'orderTotal currencyCode'">
					<template slot-scope="scope">
						<div v-if="scope.row.orderTotal">{{ scope.row.orderTotal.currencyCode }}</div>
					</template>
				</el-table-column>

				<el-table-column prop="amount" :label="'orderTotal amount'">
					<template slot-scope="scope">
						<div v-if="scope.row.orderTotal">{{ scope.row.orderTotal.amount }}</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="itemVo" :label="'sellerSKU'" min-width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.itemVo">
							<!-- {{ scope.row.itemVo.orderItems.Title }} -->
							<el-tag v-for="(item,index) in scope.row.itemVo.orderItems" :key="index">{{item.sellerSKU}}</el-tag>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="shippingAddress" :label="'address Name'" min-width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.addressVo&&scope.row.addressVo.shippingAddress">
							<span>{{ scope.row.addressVo.shippingAddress.addressLine1 }}&nbsp;</span>
							<span>{{ scope.row.addressVo.shippingAddress.addressLine2 }}&nbsp;</span>
							<span>{{ scope.row.addressVo.shippingAddress.addressLine3 }}&nbsp;</span>
							<span>{{ scope.row.addressVo.shippingAddress.city }}&nbsp;</span>
							<span>{{ scope.row.addressVo.shippingAddress.municipality }}&nbsp;</span>
							<span>{{ scope.row.addressVo.shippingAddress.distric }}&nbsp;</span>
							<span>{{ scope.row.addressVo.shippingAddress.county }}&nbsp;</span>
							<span>{{ scope.row.addressVo.shippingAddress.countryCode }}&nbsp;</span>
							<span>{{ scope.row.addressVo.shippingAddress.phone }}&nbsp;</span>
							<span>{{ scope.row.addressVo.shippingAddress.stateOrRegion }}&nbsp;</span>
							
					  </div>
					</template>
				</el-table-column>
				
				<el-table-column prop="orderBuyerInfoVo" :label="'Buyer Email'" min-width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.orderBuyerInfoVo">{{ scope.row.orderBuyerInfoVo.buyerEmail }}</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="fulfillMentChannel" label="fulfillMentChannel"></el-table-column>
				<el-table-column prop="lastUpdateDate" label="lastUpdateDate"></el-table-column>

				<el-table-column prop="purchaseDate" label="purchaseDate"></el-table-column>

				<el-table-column prop="marketplaceId" label="marketplaceId"></el-table-column>

				<el-table-column prop="sellerOrderId" label="sellerOrderId"></el-table-column>
				<!-- <el-table-column prop="userId" label="userId"></el-table-column> -->
				<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="140px" fixed="right">
				  <template slot-scope="scope">
				    <el-button @click="toDet($event,scope.row, scope.$index)" type="primary" size="mini" icon="el-icon-view">SKU明细</el-button>
				  </template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>

		<!--查看明细-->
		<!-- <el-dialog :title="'SKU'" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="10px"> -->
    <el-drawer :wrapperClosable="false" :title="'SKU'" append-to-body :visible.sync="dialogShowDet" :direction="'rtl'" size="1200px">
      <div style="">
        <el-table
            :data="DetData"
            stripe
            :border="true"
            style="width: 100%"
            size="small"
          >
            <el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

            <!-- <el-table-column prop="subjectName" :label="$t('Storage.skuInfo.category')"></el-table-column> -->

            <el-table-column prop="sellerSKU" label="sellerSKU">
            </el-table-column>

            <el-table-column prop="title" :label="'title'" min-width="200">
            </el-table-column>

            <el-table-column prop="aSIN" :label="'aSIN'">
            </el-table-column>

            <el-table-column prop="itemPrice" :label="'itemPrice'">
              <template slot-scope="scope">
                <div v-if="scope.row.itemPrice">
                  <span>{{scope.row.itemPrice.currencyCode}}</span>
									<br />
									<span>{{scope.row.itemPrice.amount}}</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="itemTax" :label="'itemTax'">
							<template slot-scope="scope">
							  <div v-if="scope.row.itemTax">
							    <span>{{scope.row.itemTax.currencyCode}}</span>
									<br />
									<span>{{scope.row.itemTax.amount}}</span>
							  </div>
							</template>
						</el-table-column>
						
						<el-table-column prop="productInfo" :label="'productInfo numberOfItems'">
							<template slot-scope="scope">
							  <div v-if="scope.row.productInfo">
							    <span>{{scope.row.productInfo.numberOfItems}}</span>
							  </div>
							</template>
						</el-table-column>
						
						
						
						<el-table-column prop="promotionDiscount" :label="'promotionDiscount'">
							<template slot-scope="scope">
							  <div v-if="scope.row.promotionDiscount">
							    <span>{{scope.row.promotionDiscount.currencyCode}}</span>
									<br />
									<span>{{scope.row.promotionDiscount.amount}}</span>
							  </div>
							</template>
						</el-table-column>
						
						<el-table-column prop="promotionDiscountTax" :label="'promotionDiscountTax'">
							<template slot-scope="scope">
							  <div v-if="scope.row.promotionDiscountTax">
							    <span>{{scope.row.promotionDiscountTax.currencyCode}}</span>
									<br />
									<span>{{scope.row.promotionDiscountTax.amount}}</span>
							  </div>
							</template>
						</el-table-column>
						
            <el-table-column prop="quantityOrdered" :label="'quantityOrdered'"></el-table-column>
						<el-table-column prop="quantityShipped" :label="'quantityShipped'"></el-table-column>

						<el-table-column prop="isGift" :label="'isGift'"></el-table-column>
						<el-table-column prop="isTransparency" :label="'isTransparency'"></el-table-column>
						
						<el-table-column prop="promotionIds" :label="'promotionIds'">
							<template slot-scope="scope">
							  <div v-if="scope.row.promotionIds">
							    <!-- <span v-for="(item,index) in scope.row.promotionIds" :key="index">{{item}}</span> -->
									{{scope.row.promotionIds.join(',')}}
							  </div>
							</template>
						</el-table-column>
						
            <!-- <el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column>
            <el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')"></el-table-column>
            <el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')"></el-table-column>
            <el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column>

            <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column> -->

          </el-table>
      </div>
      <!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button></div> -->
    </el-drawer>
		<!-- </el-dialog> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->
	</div>
</template>
<script>
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
import SelAmazonUserList from '@/components/Common/SelAmazonUserList.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	// props:{
	//   // mobile:"",
	//   isSel:{
	//     default: function() {
	//       return false
	//     },
	//     type: Boolean
	//   },
	//   status:{
	//     default: function() {
	//       return ''
	//     },
	//     type: String
	//   },
	// },
	components: {
		SelAmazonUserList
	},
	data() {
		return {
			// activeName: 'second',
			// isShowFrom:false,
			pickerOptions: this.$PickerDate.pickerOptions_5(),
			dialogShowDet: false,
			DetData:[],

			// UserInfo: this.$store.getters.getUserInfo,
			// dialogFormVisible: false,
			// dialogFormStatus: 0, //0-新增，1-修改

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',

			loading: false,

			currentSel2: {},
			radio: '',

			loading_load: false,
			tableData: [],
			//分页数据
			pagination: this.$Utils.defaultPagination(),
			selectOption: {
			//   wh_weight_unit: [],
			//   wh_vol_unit: [],
			//   wh_goods_fee_type:[],
			  statusList: [
			    {
			      value: '',
			      label: 'All'
			    },
			    {
			      value: 'Pending',
			      label: 'Pending'
			    },
			    {
			      value: 'Unshipped',
			      label: 'Unshipped'
			    },
			    {
			      value: 'Shipped',
			      label: 'Shipped'
			    }
			  ]
			},
			amazonPull: {
				spSellerId: '',
				lastUpdatedAfter: new Date()
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序
				// "accountPeriod":"",
				orderStatus:'',
				amazonOrderId:''
			}
		};
	},

	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();

		this.initData();
	},
	methods: {
		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			this.getPageData();
			// this.getDicData();
		},

		changeAmazonUser(val) {
			this.amazonPull.spSellerId = val;
			// this.initData();
		},
		//查看sku
		toDet(event,row, $index){
			event.stopPropagation();
			this.dialogShowDet = true;
			if(row.itemVo){
				this.DetData = row.itemVo.orderItems;
			}
		},
		//查询数据
		serPageData() {
			this.pagination.current_page = 1;
			this.getPageData();
		},
		//请求分页数据
		getPageData() {
			// let _this = this;
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.AmazonOrderPullPageList, {
					sortAsc: this.filterData.sortAsc,
					orderBy: this.filterData.orderBy,
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
					
					orderStatus: this.filterData.orderStatus ? this.filterData.orderStatus : null,
					amazonOrderId: this.filterData.orderId ? this.filterData.orderId : null,
				})
				.then(({ data }) => {
					console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					
					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},

		//拉取订单
		pullOrderAction() {
			this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			this.$http
				.put(this.$urlConfig.AmazonOrderPull, {
					spSellerId: this.amazonPull.spSellerId,
					lastUpdatedAfter: this.amazonPull.lastUpdatedAfter
				})
				.then(({ data }) => {
					this.loading_load = false;
					if (200 == data.code) {
						this.$message.success('拉取订单成功！');
						// this.getPageData();
						this.initData();
					} else {
						this.$message.warning(data.msg ? data.msg : '拉取订单失败,请重试');
					}
				})
				.catch(error => {
					console.log(error);
					console.log('更新特定数据，请求失败');
					this.loading_load = false;
					this.$message.warning('拉取订单失败,请重试！');
				});
		},

		//打单选择
		//选择行
		handleCurrentChange2(row) {
			console.log('handleCurrentChange2', row);
			this.tableData.map((item, index) => {
				if (item.id === row.id) {
					// item.selected = true;
					this.radio = index;
				} else {
					// item.selected = false;
				}
			});
			this.currentSel2 = row;
			console.log('currentSel2', this.currentSel2);
		},
		//多选
		handleSelectionChange2(val) {
			console.log(val);
		},

		//更新商品信息
		updateGoodsAction() {
			console.log('this.currentSel2', this.currentSel2);
			if (this.currentSel2 && this.currentSel2.id) {
				// let dataParm = {
				// 	id: this.currentSel2.id
				// };
				this.updatePageData(this.$urlConfig.AmazonOrderUpdateItem+'/'+this.currentSel2.id, this.$t('i18nn_b77563b9e5567c4a'));
			} else {
				this.$message.warning(this.$t('tips.Please_Sel'));
			}
		},
		//更新地址信息
		updateAddressAction() {
			console.log('this.currentSel2', this.currentSel2);
			if (this.currentSel2 && this.currentSel2.id) {
				// let dataParm = {
				// 	id: this.currentSel2.id
				// };
				this.updatePageData(this.$urlConfig.AmazonOrderUpdateAddress+'/'+this.currentSel2.id, this.$t('i18nn_10556bca2482ac06'));
			} else {
				this.$message.warning(this.$t('tips.Please_Sel'));
			}
		},
		//更新买家信息
		updateBuyinfoAction() {
			console.log('this.currentSel2', this.currentSel2);
			if (this.currentSel2 && this.currentSel2.id) {
				// let dataParm = {
				// 	id: this.currentSel2.id
				// };
				this.updatePageData(this.$urlConfig.AmazonOrderUpdateBuyinfo+'/'+this.currentSel2.id, this.$t('i18nn_a7926683203307f0'));
			} else {
				this.$message.warning(this.$t('tips.Please_Sel'));
			}
		},
		//更新特定数据,
		updatePageData(url, msg) {
			// let _this = this;
			this.loading_load = true;
			//浅拷贝、对象属性的合并
			// let dataParmAss = Object.assign({}, dataParm);
			// dataParmAss.isDefault = 1;
			this.$http
				.put(url, {})
				.then(({ data }) => {
					console.log(msg + '特定数据，请求成功');
					console.log(data);
					if (200 == data.code) {
						this.$message.success(msg + '成功！');
						this.getPageData();
					} else {
						this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
					}
				})
				.catch(error => {
					console.log(error);
					console.log('更新特定数据，请求失败');
					this.loading_load = false;
					this.$message.warning('' + msg + '失败,请重试！');
				});
		}
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// /deep/ .el-input-number,
//   .el-select {
//     // width: 100px;
//     .el-input__inner {
//       text-align: left;
//     }
//   }
// .form_msg {
//   color: #e6a23c;
// }
</style>
